<script setup lang="ts">
import Logo from '~/components/design-system/logo.vue';
type LoaderSize = 'normal' | 'small';

interface ILoaderProps {
  fullScreen?: boolean;
  container?: boolean;
  standalone?: boolean;
  noBox?: boolean;
  size?: LoaderSize;
  overridePositioning?: string;
}

interface ILoaderValues {
  width: string;
  height: string;
  borderRadius: string;
  bgImage: string;
  keymoves: string[];
  class: string;
}

const props = withDefaults(defineProps<ILoaderProps>(), {
  fullScreen: false,
  container: false,
  overridePositioning: '',
  standalone: true,
  noBox: true,
  size: 'normal',
});

const positioning = computed(() => {
  if (props.fullScreen)
    return 'fixed top-0 left-0 w-screen h-screen z-[25] bg-light-900/80 dark:bg-dark-900/80';
  if (props.container)
    return 'absolute top-0 left-0 w-full h-full z-[25] bg-light-900/80 dark:bg-dark-900/80';
  if (props.standalone)
    return 'relative top-0 left-0 container mx-auto h-[calc(4px*90)]';
  if (props.noBox) return 'relative top-0 left-0 container mx-auto h-20';
  return '';
});

const logoClasses =
  'w-48 cursor-pointer bg-no-repeat bg-contain md:bg-center xs:mt-1 h-max-content object-cover mb-4';
</script>

<template>
  <div>
    <div class="flex-center" :class="positioning + ' ' + overridePositioning">
      <div class="absolute flex flex-col items-center">
        <Logo
          v-if="props.fullScreen"
          :image-class="logoClasses"
        />
        <svg
          id="eIkixLWChHr1"
          :key="positioning"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 72 72"
          shape-rendering="geometricPrecision"
          text-rendering="geometricPrecision"
        >
          <g id="eIkixLWChHr2_tr" transform="translate(36,36) rotate(0)">
            <g transform="translate(-36,-36)">
              <rect
                width="54"
                height="54"
                rx="7.2"
                ry="7.2"
                transform="translate(9 9)"
                fill="#42abff"
              />
              <circle
                id="eIkixLWChHr4"
                r="5.4"
                transform="translate(36 36)"
                opacity="0"
                fill="#fff"
              />
              <g
                id="eIkixLWChHr5_to"
                transform="translate(36.000001,36.000001)"
              >
                <circle
                  r="5.4"
                  transform="translate(-0.000001,-0.000001)"
                  fill="#fff"
                />
              </g>
              <g
                id="eIkixLWChHr6_to"
                transform="translate(36.000001,36.000001)"
              >
                <circle
                  r="5.4"
                  transform="translate(-0.000001,-0.000001)"
                  fill="#fff"
                />
              </g>
              <g
                id="eIkixLWChHr7_to"
                transform="translate(36.000001,35.999998)"
              >
                <circle
                  r="5.4"
                  transform="translate(-0.000001,0.000002)"
                  fill="#fff"
                />
              </g>
              <g
                id="eIkixLWChHr8_to"
                transform="translate(35.999998,36.000001)"
              >
                <circle
                  r="5.4"
                  transform="translate(0.000002,-0.000001)"
                  fill="#fff"
                />
              </g>
              <g
                id="eIkixLWChHr9_to"
                transform="translate(35.999998,36.000001)"
              >
                <circle
                  r="5.4"
                  transform="translate(0.000002,-0.000001)"
                  fill="#fff"
                />
              </g>
              <g
                id="eIkixLWChHr10_to"
                transform="translate(35.999998,35.999998)"
              >
                <circle
                  r="5.4"
                  transform="translate(0.000002,0.000002)"
                  fill="#fff"
                />
              </g>
            </g>
          </g>
        </svg>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
